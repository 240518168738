import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "tradition2",
  casinoId: 93,
  GA_TRACKING:'G-LN5F60RKR2',

  rivalChatGroupName: "Tradition",
  prettyName: "Tradition Casino",
  contactEmail: "support@traditioncasino.com",
  docsEmail: "documents@traditioncasino.email",

  assetsPath: "assets/brands/tradition",

  //SEO
  metaDescription: "Classic yet Classy Online Casino? Join easily to Tradition Casino online! Play over 400+ games: rogressive slots, live dealer tables, blackjack roulette. Get top casino bonuses, fast payouts and secure online gaming on our trusted Casino. Earn real money, bet easy, win the jackpot today!",
  logoName: "tradition-casino-online-casino-logo.png",
  logoAltText: "Tradition Casino online logo with elegant gold lettering and vintage design known for classic slots table games and exclusive gambling rewards."
};


